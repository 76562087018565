import React from "react"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import ContactImage from "../assets/images/male_contact.jpg";
import {Link} from "gatsby";

const Contact = () => (
  <Layout>
    <Seo title="Thank you" index={false} follow={false}  />

    <Navbar />

    {/*<PageBanner pageTitle="Contact Us" />*/}

      <div className="pt-120-b-80">
          <div className="container">
              <div className="section-title">
                  {/*<span className="sub-title">Our Solutions</span>*/}
                  <h1>Thank you</h1>
                  <div className="bar"/>
                  {/*<p>Drop us a Message</p>*/}
              </div>
              <div className="row justify-content-center">
                  <div className="col-lg-6 d-none d-lg-block">
                      <img src={ContactImage} alt="about" className="w-full" />
                  </div>
                  <div className="col-lg-6 text-center my-5">
                      <h3 className="my-5">Thanks for submitting the form, our team will get back to you as soon as possible.</h3>

                      <Link to={'/'} className="btn btn-primary">
                          Go to Home
                      </Link>
                  </div>
              </div>


          </div>
      </div>


    <Footer />
  </Layout>
)

export default Contact
